.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

.progress-bar {
  background-color: #2f55d4;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

#bottomIcon {
  aside {
    border-radius: 10px !important;
    height: 30px !important;
    position: fixed !important;
    bottom: 20px !important;
    width: 30px !important;
    transition: opacity 0.5s ease-in-out 0s, right 0s ease 0s !important;
    cursor: pointer !important;
    opacity: 1 !important;
    right: 20px !important;
    line-height: 26px !important;
    background-color: rgb(73, 80, 87) !important;
    text-align: center;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 3px;
    text-align: center;
    border: none !important;
    &:hover {
      background-color: rgb(47, 85, 212) !important;
    }
    svg {
      height: 12px;
      width: 12px;
      fill: #fff;
    }
  }
}

/* ##################################################################### */
/* ##################################################################### */
/* ##################################################################### */
/* My own CSS */

/* Generic customization */

.btn-shadow-custom {
  box-shadow: 0px 8px 15px 0px rgba(12, 9, 9, 0.32) !important;
  border-radius: 0px !important;
}

.btn-shadow-custom:hover {
  /* TODO: Currently does NOT work yet */

  background-color: #fff !important;
  border-color: $primary !important;
  color: $primary !important;
}

/* Top bar */
#topnav .navigation-menu > li > a {
  line-height: 30px !important;
}

/* ################################################################ */
/* ########################## Homepage ############################ */

.mitsHomeSectionH1 {
  text-align: justify;
}

.mitsHomeSectionP {
  text-align: justify;
}

.bg-half-170-update-mits {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.section-update-mits {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@media (min-width: 768px) {
  .container-update-mits-home-partners {
    /* TODO :: should not be the case for small screens. Now it looks shit on mobile. */

    padding-bottom: 15px;
    margin-top: -150px;
  }
}

/* MarketingInsightsSection Home*/
.para-desc-mitsjol {
  width: 80%;
}

.market-insight-image-mitsjol {
  top: -60px !important;
}

/* Home - Testimonial */
.mitsjol-testimonial-head {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #cccccc;
}

/* Home - Testimonial */
.mitsjol-news-containe-home {
  padding-bottom: 200px;
}

/* Home - Testimonial divider below */
@media (min-width: 768px) {
  .mitsjol-divider-below-testimonials {
    margin-top: -200px;
  }
}

/* Home - marketinginsights tool */
.mitsjol-marketing-insights {
  margin-top: -230px;
}

@media (max-width: 768px) {
  .mitsjol-marketing-insights {
    margin-top: -150px;
  }
}

.mitsjol-marketing-insights-section2 {
  position: relative;
}

.mitsjol-marketing-insights-features {
  margin-top: 75px;
}

.mitsjol-marketing-insights-screenshotsection {
  margin-top: 100px;
}

/* ########################################################################## */
/* ########################## SEO Framework Page ############################ */

.mits-heading-left-play-icon-frw {
  bottom: 45% !important;
}

.mits-section-head-frw {
  padding-top: 165px !important;
  padding-bottom: 165px !important;
}

.mits-heading-right-frw {
  margin-left: 13%;
}

@media (max-width: 768px) {
  .mits-heading-right-frw {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.mits-heading-frw {
  /* font-family: Rubik; Change this later with proper i*/
  font-size: 38px !important;
}

.mits-heading-p-frw {
  text-align: justify;
  color: #73747f !important;
}

.mits-heading-right-btn-frw {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mits-heading-right-btn-text-frw {
  font-weight: 300;
  font-size: 14px;
}
